//--- API calls ---
export class ChartBuilderService {
  constructor(api) {
    this.api = api;
  }

  async getSites() {
    let url = `/trends/sites`;
    return this.api.pyGet(url);
  }

  async getBuildings(siteId) {
    let url = `/trends/buildings?siteid=${siteId}`;
    return this.api.pyGet(url);
  }

  async getEquipClasses(siteId, buildingIds, pointIds = []) {
    let url = `/chart-builder/equipclass`;

    let request = {
      siteId: siteId,
      buildingId: buildingIds,
      pointId: pointIds
    };
    return this.api.post(url, request);
  }

  async getEquipment(siteId, buildingIds, equipClassIds, pointIds = []) {
    let url = `/chart-builder/equipment`;

    let request = {
      siteId: siteId,
      buildingId: buildingIds,
      equipClassId: equipClassIds,
      pointId: pointIds
    };
    return this.api.post(url, request);
  }
 
  async getPointClasses(siteId, buildingIds, equipClassIds, equipIds, pointIds = []) {
    let url = `/chart-builder/pointclass`;

    let request = {
      siteId: siteId,
      buildingId: buildingIds,
      equipClassId: equipClassIds,
      equipId: equipIds,
      pointId: pointIds
    };

    return this.api.post(url, request);
  }

  async getPoints(
    siteId,
    buildingIds,
    equipClassIds,
    equipIds,
    pointClassIds,
    pointIds = [],
    search,
    isRaw
  ) {
    let url = `/chart-builder/point`;

    let request = {
      siteId: siteId,
      buildingId: buildingIds,
      equipClassId: equipClassIds,
      equipId: equipIds,
      pointClassId: pointClassIds,
      pointId: pointIds,
      search: search,
      isRaw: isRaw,
    };

    return this.api.post(url, request);
  }

  async getPointCount(
    siteId,
    buildingIds,
    equipClassIds,
    equipIds,
    pointClassIds,
    search,
    isRaw
  ) {
    let url = `/chart-builder/count`;
    let request = {
      siteId: siteId,
      buildingId: buildingIds,
      equipClassId: equipClassIds,
      equipId: equipIds,
      pointClassId: pointClassIds,
      search: search,
      isRaw: isRaw,
    };

    return this.api.post(url, request);
  }

  async getTrendData(
    configId,
    pointIds,
    startDate,
    endDate,
    interval = 1,
    intervalUnit = 'hour',
    stat = 'average',
    timezone = 'UTC'
  ) {
    let url = `/trends/timeseries`;
    this.trendDataParams = {
      configId,
      pointIds,
      startDate,
      endDate,
      interval,
      intervalUnit,
      stat,
      timezone,
    };

    let data = {
      configId: configId,
      pointIds: pointIds,
      end: endDate,
      begin: startDate,
      interval,
      intervalUnit,
      stat,
      timezone,
    };
    return this.api.post(url, data);
  }

  async getCSVData(
    configId,
    pointIds,
    startDate,
    endDate,
    interval = 1,
    intervalUnit = 'hour',
    stat = 'average',
    isRawPointName
  ) {
    let url = `/trends/timeseries?output=csv`;
    this.trendDataParams = {
      configId,
      pointIds,
      startDate,
      endDate,
      interval,
      intervalUnit,
      stat,
    };

    let data = {
      configId: configId,
      pointIds: pointIds,
      end: endDate,
      begin: startDate,
      stat: stat,
      interval: interval,
      intervalUnit: intervalUnit,
      isRawPointName: isRawPointName,
      scatterXAxisPoint: null,
      scatterYAxisPoint: null,
      scatterSizePoint: null,
      scatterColorPoint: null,
    };
    return this.api.post(url, data);
  }

  async getScatterCSVData(
    pointIds,
    startDate,
    endDate,
    interval = 1,
    intervalUnit = 'hour',
    stat = 'average',
    isRawPointName,
    scatterXAxisPoint,
    scatterYAxisPoint,
    scatterSizePoint,
    scatterColorPoint
  ) {
    let url = `/trends/timeseries?output=csv`;
    this.trendDataParams = {
      pointIds,
      startDate,
      endDate,
      interval,
      intervalUnit,
      stat,
    };

    let data = {
      pointIds: pointIds,
      end: endDate,
      begin: startDate,
      stat: stat,
      interval: interval,
      intervalUnit: intervalUnit,
      isRawPointName: isRawPointName,
      scatterXAxisPoint: scatterXAxisPoint,
      scatterYAxisPoint: scatterYAxisPoint,
      scatterSizePoint: scatterSizePoint,
      scatterColorPoint: scatterColorPoint,
    };
    return this.api.post(url, data);
  }

  async getLibraryData(userId, configId) {
    let url = `/chart-builder/library/${userId}?configId=${configId}`;
    return this.api.get(url);
  }

  async getChartDetail(chartId) {
    let url = `/chart-builder/${chartId}`;
    return this.api.get(url);
  }

  async postChart(chart) {
    let url = `/chart-builder/`;
    console.log(chart);
    return this.api.post(url, chart);
  }

  async putChart(chartId, chartData) {
    let url = `/chart-builder/${chartId}`;
    return this.api.put(url, chartData);
  }

  async deleteChart(chartId) {
    let url = `/chart-builder/${chartId}`;
    return this.api.delete(url);
  }

  async getShareableUsers(chartId, configId) {
    let url = `/chart-builder/share/users/list/${chartId}?configId=${configId}`;
    return this.api.get(url);
  }

  async postSharedUsers(chartId, userIds) {
    let url = `/chart-builder/share/`;
    let shareChartRequest = {
      chartId: chartId,
      users: userIds,
    };
    return this.api.post(url, shareChartRequest);
  }
}
